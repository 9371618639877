.runchang-container {
  .img {
    width: 100vw;
  }
  .button {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 60px;
    background-color: pink;
    border-radius: 55px;
    color: aliceblue;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 58px;
    background-color: rgb(244, 183, 110);
    box-shadow: 10px 10px 5px rgba(239, 148, 58, 0.3);
    border: 2px solid rgb(255,255,255,.3);
  }
  .beian {
    line-height: 22px;
    color: rgba(0,0,0,.4);
    text-align: center;
  }
}