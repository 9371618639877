.root-view-layout {
  min-height: 100vh;
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 54px;
    margin: 16px;
    img {
      width: 40px;
      height: 40px;
    }
    &-text {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin-left: 10px;
    }
    border-bottom: 1px solid rgba(255, 255, 255, .3);
  }

  .site-layout {
    min-height: 100vh;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
}
